// [Responsive]
//==================================================

$responsive-size-big-laptop-screen  : 1499px;
$responsive-size-laptop-screen  : 1399px;
$responsive-size-big-screen     : 1280px;
$responsive-size-desktop        : 1199px;
$responsive-size-big-tablet     : 991px;
$responsive-size-tablet         : 824px;
$responsive-size-stand-mobile   : 767px;
$responsive-size-big-mobile     : 640px;
$responsive-size-mobile         : 480px;
$responsive-size-phone          : 320px;

$text-color: #292F45;
$color-orange: #f98226;
$white: #fff;
$footer-bg: #fff;

