@import "../../_helpers/variables";

.app {
    footer {
        margin-top: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #9096AA;
        background: $footer-bg;
        box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.02);
        padding: 17px 0;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            @media only screen and (max-width : $responsive-size-desktop) {
                flex-direction: column;
            }
        }
        p {
            margin: 0;
        }
        .left {
            display: flex;
            align-items: center;
            @media only screen and (max-width : $responsive-size-desktop) {
                flex-direction: column;
            }
        }
        .logo {
            display: block;
            margin-right: 100px;
            @media only screen and (max-width : $responsive-size-desktop) {
                margin-right: 0;
            }
            svg {
                display: block;
            }
            &:hover {
                svg {
                    path {
                        fill:#FF7700;
                    }
                }
            }
        }
        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-decoration: underline;
            color: #9196AA;
            display: inline-flex;
            @media only screen and (max-width : $responsive-size-desktop) {
                margin-bottom: 20px;
            }
            &:hover {
                color: #FF7700;
                text-decoration: underline;
            }
        }
        .copyright {
            text-align: center;
        }
        .social {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
            @media only screen and (max-width : $responsive-size-desktop) {
                width: 100%;
                justify-content: center;
                margin-bottom: 20px;
            }
            li {
                margin-left: 50px;
                @media only screen and (max-width : $responsive-size-desktop) {
                    margin-left: 20px;
                }
                &:first-child {
                    margin-left: 0;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all linear 0.2s;
                    text-decoration: none;
                    margin: 0;
                    img,
                    svg {
                        display: block;
                        transition: all linear 0.2s;
                    }
                    &:hover {
                        svg {
                            path {
                                fill:#FF7700;
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            @media only screen and (max-width : $responsive-size-desktop) {
                text-align: center;
            }
        }
    }
}