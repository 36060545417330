@import "../../_helpers/variables";

.app {
    .dashboard-block {
        //overflow: hidden;
        @media only screen and (max-width: $responsive-size-desktop) {
            overflow: initial;
        }
        .holder-block {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 -15px;
            @media only screen and (max-width: $responsive-size-desktop) {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .block-wrapper {
            //width: calc(33.3% - 30px);
            width: 100%;
            margin-left: 15px;
            margin-right: 15px;
            @media only screen and (max-width: 1699px) {
                //width: calc(50% - 30px);
            }
            @media only screen and (max-width: $responsive-size-desktop) {
                //width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
            &.balance-block {
                background: linear-gradient(114.37deg, #3d4460 0%, #6b7392 100%);
            }
            &.stake-block {
                background: linear-gradient(114.37deg, #3d4460 0%, #6b7392 100%);
            }
            &.reward-block {
                background: linear-gradient(114.37deg, #3d4460 0%, #6b7392 100%);
            }
            &.claim-block {
                background: linear-gradient(114.37deg, #3d4460 0%, #6b7392 100%);
            }
            .balance-info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                @media only screen and (max-width: $responsive-size-stand-mobile) {
                    margin-bottom: 20px;
                    flex-direction: column;
                }
                .left {
                    flex-basis: 33.333%;
                    box-sizing: border-box;

                    //width: 30%;
                    //flex-direction: row;
                    //width: 300px;
                    justify-content: space-between;
                    @media only screen and (max-width: $responsive-size-stand-mobile) {
                        margin-bottom: 20px;
                    }
                    .number {
                        display: block;
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 48px;
                        color: #ffffff;
                        margin-bottom: 10px;
                        @media only screen and (max-width: $responsive-size-desktop) {
                            font-size: 32px;
                            line-height: 40px;
                        }
                        @media only screen and (max-width: $responsive-size-stand-mobile) {
                            font-size: 30px;
                            line-height: 36px;
                        }
                    }
                    .current-info {
                        background: rgba(255, 255, 255, 0.15);
                        border-radius: 15px;
                        padding: 10px 23px;
                        display: inline-flex;
                        flex-direction: column;
                        margin-right: 15px;
                        margin-top: 10px;
                        min-width: 170px;
                        .name {
                            display: block;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: #ffffff;
                            opacity: 0.75;
                        }
                        strong {
                            display: block;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 22px;
                            color: #ffffff;
                        }
                    }
                    .progress-bar {
                        width: 89%;
                        background-color: white;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                    .progress {
                        width: 0%;
                        height: 30px;
                        background-color: rgb(32, 86, 232);
                        border-radius: 5px;
                        transition: width 2s ease-in-out;
                    }
                }
                .extra-info {
                    //max-width: 156px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    @media only screen and (max-width: $responsive-size-stand-mobile) {
                        flex-direction: row;
                        justify-content: space-between;
                        max-width: 100%;
                    }
                    .percent-info {
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 36px;
                        color: #ffffff;
                        border: 3px solid rgba(255, 255, 255, 0.15);
                        border-radius: 15px;
                        padding: 12px 20px;
                        display: inline-flex;
                        margin-bottom: 10px;
                        @media only screen and (max-width: $responsive-size-stand-mobile) {
                            margin-bottom: 0;
                        }
                    }
                    .day-info {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        span {
                            display: block;
                            width: calc(100% - 50px);
                            text-align: right;
                            padding-right: 5px;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: #ffffff;
                            opacity: 0.75;
                        }
                        strong {
                            min-width: 50px;
                            min-height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(255, 255, 255, 0.15);
                            border-radius: 15px;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #ffffff;
                            padding: 3px;
                        }
                    }
                }
            }
            .btn-holder {
                display: flex;
                flex-direction: column;
                //flex-wrap: wrap;
                @media only screen and (max-width: $responsive-size-big-mobile) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .btn {
                    width: 200px;
                    margin-top: 10px;
                    //& + .btn {
                    //    //margin-left: 10px;
                    //    margin-top: 10px;
                    //    @media only screen and (max-width: $responsive-size-big-mobile) {
                    //        margin-left: 0;
                    //        margin-top: 10px;
                    //    }
                    //}
                }
            }
        }
        .history-wrapper {
            width: calc(50% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
            background: #f0f3f9;
            border-radius: 5px;
            overflow: hidden;
            padding-bottom: 20px;
            position: relative;
            @media only screen and (max-width: $responsive-size-desktop) {
                width: 100%;
                margin: 30px 0 30px;
            }
            @media only screen and (max-width: $responsive-size-mobile) {
                padding-bottom: 66px;
            }
            .head {
                padding: 40px 40px 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media only screen and (max-width: $responsive-size-big-mobile) {
                    padding: 30px 30px 20px;
                }
                h3 {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    color: #616a88;
                    opacity: 0.75;
                    margin-bottom: 0;
                }
                .link {
                    @media only screen and (max-width: $responsive-size-mobile) {
                        position: absolute;
                        bottom: 30px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .total-holder {
            width: calc(50% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            overflow: hidden;
            @media only screen and (max-width: 1699px) {
                width: 100%;
                margin: 0;
            }
            .inner {
                display: flex;
                justify-content: space-between;
                margin: 0 -15px;
                @media only screen and (max-width: $responsive-size-desktop) {
                    flex-direction: column;
                    margin: 0;
                }
                .item {
                    margin: 0 15px;
                    border-radius: 5px;
                    padding: 40px;
                    width: calc(33.3% - 30px);
                    @media only screen and (max-width: $responsive-size-desktop) {
                        margin: 0 0 10px;
                        width: 100%;
                    }
                    @media only screen and (max-width: $responsive-size-big-mobile) {
                        padding: 30px;
                    }
                    &.total-stake {
                        background: #e5f9ef;
                        border: 1px solid rgba(37, 215, 128, 0.25);
                        h3 {
                            color: #34b175;
                        }
                    }
                    &.total-liquidity {
                        background: #fff4ef;
                        border: 1px solid #ffe4d8;
                        h3 {
                            color: #ff8523;
                        }
                    }
                    &.total-user {
                        background: #eef1fb;
                        border: 1px solid #dce1f2;
                        h3 {
                            color: #617fa4;
                        }
                    }
                    h3 {
                        margin-bottom: 40px;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                    }
                    .holder-info {
                        display: flex;
                        flex-direction: column;
                        @media only screen and (max-width: 1699px) {
                            flex-direction: row;
                            align-items: center;
                        }
                    }
                    .info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        @media only screen and (max-width: 1699px) {
                            width: calc(100% - 100px);
                            padding-left: 20px;
                        }
                        @media only screen and (max-width: $responsive-size-desktop) {
                            width: calc(100% - 100px);
                            padding-left: 20px;
                            flex-direction: row;
                            align-items: center;
                        }
                        @media only screen and (max-width: $responsive-size-big-mobile) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                    .ico {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100px;
                        height: 100px;
                        background: rgba(255, 255, 255, 0.5);
                        margin-bottom: 20px;
                        border-radius: 50%;
                        @media only screen and (max-width: 1699px) {
                            margin-bottom: 0;
                        }
                        @media only screen and (max-width: $responsive-size-big-mobile) {
                            width: 75px;
                            height: 75px;
                        }
                        svg {
                            display: block;
                            @media only screen and (max-width: $responsive-size-big-mobile) {
                                max-width: 35px;
                                height: auto;
                            }
                        }
                    }
                    .number {
                        display: block;
                        margin-bottom: 10px;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 39px;
                        color: #2e344b;
                        @media only screen and (max-width: $responsive-size-desktop) {
                            padding-right: 10px;
                            margin-bottom: 0;
                        }
                        @media only screen and (max-width: $responsive-size-big-mobile) {
                            padding-right: 0;
                            margin-bottom: 10px;
                        }
                        @media only screen and (max-width: $responsive-size-big-mobile) {
                            font-size: 28px;
                            line-height: 34px;
                        }
                    }
                    .current-info {
                        background: rgba(255, 255, 255, 0.5);
                        border-radius: 5px;
                        padding: 10px 30px;
                        display: inline-flex;
                        flex-direction: column;
                        .name {
                            display: block;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: #2e344b;
                            opacity: 0.5;
                        }
                        strong {
                            display: block;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 22px;
                            color: #616a88;
                        }
                    }
                }
            }
        }
    }
}
