@import "../../_helpers/variables";
.appLogin,
.app {
  label {
    margin: 0;
  }
  .form {
    width: 100%;
    margin: 0 auto;
  }
}

.btn-box {
  margin-top: 40px;
}

select {
  appearance: none;
}

body {
  .input-box {
    label {
      cursor: pointer;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #1E2632;
      display: block;
      margin-bottom: 10px;
      strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #3C7CF9;
      }
    }
    select,
    input:not([type="range"]) {
      border: none !important;
      background: #F4FAFF;
      border-radius: 4px;
      outline: none !important;
      padding: 15px 19px;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #3C7CF9;
      min-height: 50px;
      &:focus,
      &:active {
        border: none;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    select {
      border: 1px solid transparent;
      background-color: #E7EEFC;
      border-radius: 4px;
      outline: none !important;
      padding: 15px 19px;
      width: 100%;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #1E2632;
      min-height: 50px;
      -webkit-appearance:none;
    }
    .range-holder {
      padding-top: 3px;
      .number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 4px;
      }
    }
    .rc-slider {
      height: 6px;
      padding: 7px 6px;
      width: 100%;
      border-radius: 6px;
      .rc-slider-rail {
        background: #F4F7FC;
        border-radius: 6px;
        height: 6px;
      }
      .rc-slider-track {
        background: linear-gradient(77.03deg, #3C7CF9 0%, #5BCEFF 100%);
        border-radius: 6px;
        height: 6px;
      }
      .rc-slider-handle {
        border: 4px solid #5BCEFF;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        margin-top: -7px;
        background: #FFFFFF;
      }
    }
  }

  .form-control
  //input:not([type='submit']):not(#react-select-2-input):not(input[type="range"]) {
  {
    position: relative;
    background: #F0F2F4;
    border-radius: 5px;
    padding: 9px 20px 3px;
    height: 50px;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    input {
      outline: none;
      background: transparent;
      border-radius: 4px;
      border: none !important;
      box-sizing: border-box;
      display: block;
      padding: 0;
      //height: 25px;
      width: 100%;
      font-size: 14px;
      line-height: 14px;
      height: 20px;
      color: #1E2632;
      transition: all linear 0.2s;
      -webkit-appearance:none;
      &::placeholder {
        font-size: 14px;
        line-height: 17px;
        color: #9FB0D0;
      }
      &:focus,
      &:active {
        border: none;
        background: none;
        outline: none !important;
        box-shadow: none !important;
      }
      /*&.active,
      &:active,
      &:focus {
         + label {
          top: 9px;
           height: initial;
        }
      }*/
    }
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #9FB0D0;
      display: flex;
      align-items: center;
      position: absolute;

      /*top: 17px;
      width: 100%;*/
      top: 9px;
      height: initial;

      left: 20px;
      transition: all linear 0.2s;
    }
    select {
      outline: none;
      border: none;
      background: transparent;
    }
    &.error {
      border: 1px solid #FF386A;
      background-color: #F0F2F4;
    }


    &.twoFa {
      padding: 15px 19px 15px 19px;
    }
  }
  textarea {
    outline: none;
    background: #FEF9F3;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    padding: 15px 19px;
    height: 150px;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #292F45;
    transition: all linear 0.2s;
    resize: none;
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
      color: #292F45;
    }
    &:focus {
      border: 1px solid #FF8225;
    }
    &.error {
      border: 1px solid #FF386A;
    }
  }
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #9FB0D0;
    display: block;
  }


  input[type="range"] {
    padding: 1px !important;
    height: 8px;
    background: #F0F2F4;
    border: none !important;
    -webkit-appearance: none;
    //overflow: hidden;
    outline: none !important;
    border-radius: 8px;

    -webkit-writing-mode: horizontal-tb !important;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      cursor:pointer;
      border: 5px solid #292F45;
      background: #ffffff;
      //box-shadow: -1012px 0 0 1000px #292F45;
    }
  }
}

.check {
  input[type="radio"],
  input[type="checkbox"] {
    display: none !important;
    &:checked + label {
      &:before {
        background: linear-gradient(77.03deg, #3C7CF9 0%, #5BCEFF 100%);
        border-radius: 15px;
        border-color: #fff;
      }
      &:after {
        background: #FFFFFF;
        left: 21px;
      }
    }
  }
  label {
    position: relative;
    padding-left: 57px;
    cursor: pointer;
    min-height: 25px;
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #1E2632;
    img {
      display: inline-block;
      margin-right: 6px;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #E7EEFC;
      background: #E7EEFC;
      box-sizing: border-box;
      border-radius: 15px;
      content: '';
      display: block;
      z-index: 1;
      transition: all linear 0.2s;
      width: 42px;
      height: 25px;
    }
    &:after {
      position: absolute;
      top: 4px;
      left: 5px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      content: '';
      display: block;
      transition: all linear 0.2s;
      z-index: 2;
    }
  }
}

input, textarea {
  -webkit-appearance:none;
  &::-webkit-input-placeholder {
    color: #80838D;
  }
  &::-moz-placeholder {
    color: #80838D;
  }
  &:-ms-input-placeholder {
    color: #80838D;
  }
  &:-moz-placeholder {
    color: #80838D;
  }
  &:focus,
  &:active {
    border: none;
    background: none;
    outline: none !important;
    box-shadow: none !important;
  }
}
.form-check {
  padding-top: 20px;
}
.form {
  .input-box {
    &+.input-box {
      margin-top: 20px;
    }
  }
}
.token-form {
  .form {
    max-width: 500px;
    margin: 0 auto;
    label {
      text-align: center;
    }
    .btn-holder {
      @media only screen and (max-width : $responsive-size-mobile) {
        flex-direction: column;
        .btn {
          &+.btn {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
