@import "../../_helpers/variables";
.responsiveTable {
    &.table {
        border-collapse: collapse;
        border: none;
        margin: 0;
        > :not(:first-child) {
            border-top: none;
        }




        tr {
            border: none !important;
        }
        th,td {
            padding:20px 12.5px;
            vertical-align: middle;
            text-align: center;
            //width: 20%;
            //@media only screen and (max-width : $responsive-size-big-mobile) {
            //    width: 100%;
            //}
            &:first-child {
                text-align: left;
                padding-left: 40px;
            }
            &:last-child {
                text-align: right;
                padding-right: 40px;
            }
        }
        th {
            border: none;
            padding-top: 0;
            padding-bottom: 20px;

            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #2E344B;
        }
        td {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #616A88;
            &.pivoted {
                .tdBefore {
                    left: 30px;
                    width: calc(30% - 20px);
                }
                @media only screen and (max-width : $responsive-size-big-mobile) {
                    text-align: right !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-right: 30px;
                    padding-left: calc(30% + 10px) !important;
                    &:first-child {
                        padding-top: 10px;
                    }
                    &:last-child {
                        padding-bottom: 10px;
                        padding-right: 30px;
                    }
                }
            }
            a {
                color: #616A88;
                text-decoration: underline;
                &:hover {
                    text-decoration: underline;
                    color: #FF7700;
                }
            }
         }
        tbody {
            td {
                border:none;
            }
            tr {
                border: none;
                &:nth-child(2n-1) {
                    td {
                        background: rgba(255, 255, 255, 0.4);
                    }
                }
            }
        }
    }
}