.toggle-switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.switch {
  display: none;
}

.slider {
  width: 30px;
  height: 15px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  transition: 0.4s;
}

.slider:before {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: 0.4s;
}

input[type="checkbox"]:checked + .slider:before {
  transform: translateX(15px);
}

input[type="checkbox"]:checked + .slider {
  background-color: #2196F3;
}

.text-label {
  position: relative;
  margin-right: 10px;
}