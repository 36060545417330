@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
p, span, a {

}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
    &:hover {
      text-decoration: none;
    }
}

html {

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
  }
  h5 {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

body {
  background: $white;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 23px;
  color: #667182;
  margin: 0;
  padding: 0;
  //overflow-x: hidden;
  &.hide {
    @media only screen and (max-width : $responsive-size-big-screen) {
      overflow: hidden;
    }

  }
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.wrapper {
  overflow: hidden;
  flex-grow: 2;
  padding: 40px 0;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  word-break: break-all;
}

ul, li {
  list-style-type: none;
}

hr {
  margin: 0 0 30px;
  opacity: 1;
  background: #F4F7FC;
}

.copyright {
  p {
    margin: 0;
  }
}
//flex

.flex {
  display: flex;
}

.f-align-center {
  align-items: center;
}

.f-align-end {
  align-items: flex-end;
}

.f-align-start {
  align-items: flex-start;
}

.f-content-end {
  justify-content: flex-end;
}
.f-content-start {
  justify-content: flex-start;
}
.f-content-start-i {
  justify-content: flex-start !important;
}

.f-space-between {
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-i {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.flex-column-mob {
  @media only screen and (max-width : $responsive-size-stand-mobile) {
    flex-direction: column;
  }
}
//end flex

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #616A88;
  svg {
    display: block;
    margin-right: 10px;
  }
  &:hover {
    text-decoration: none;
    color: #FF7700;
  }
}
.m-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mt0i {
  margin-top: 0px !important;
}
.mt20 {
  margin-top: 20px;
}
.mt20m {
  @media only screen and (max-width : $responsive-size-stand-mobile ) {
    margin-top: 20px;
  }
}
.mt20i {
  margin-top: 20px !important;
}

.mb10 {
  margin-bottom: 10px;
}
.mb15m {
  @media only screen and (max-width : $responsive-size-big-tablet) {
    margin-bottom: 15px;
  }
}
.mb15 {
  margin-bottom: 15px;
}
.mb25 {
  margin-bottom: 25px;
}

.mt30 {
  margin-top: 30px;
}
.mb30m {
  @media only screen and (max-width : $responsive-size-big-tablet) {
    margin-bottom: 30px;
  }
}

.mt35 {
  margin-top: 35px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb60 {
  margin-bottom: 60px;
}
.m-0-auto {
  margin: 0 auto !important;
}

a {
  cursor: pointer;
}

.app {
  .height-auto {
    height: auto;
  }
}

.opacity-50 {
  opacity: .5;
}

ul, ol {
  padding: 0;
  margin: 0;
}
h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #2E344B;
  margin-bottom: 0;
  @media only screen and (max-width : $responsive-size-big-tablet) {
    font-size: 30px;
    line-height: 36px;
  }
}
.head-page {
  margin-bottom: 30px;
  @media only screen and (max-width : $responsive-size-big-screen) {
    margin-bottom: 20px;
  }
}
.container {
  padding-left: 20px;
  padding-right: 20px;
  @media only screen and (min-width : 1820px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
  @media only screen and (min-width : 1200px) {
    max-width:1820px !important;
  }
  &.full {
    @media only screen and (min-width : 1200px) {
      max-width:1400px !important;
    }
  }

  @media only screen and (max-width : $responsive-size-stand-mobile) {
    max-width:100% !important;
  }
}

.block-wrapper {
  box-shadow: 5px 10px 20px 5px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  //@media only screen and (max-width : $responsive-size-stand-mobile) {
  //  margin-bottom: 10px;
  //}
  .holder {
    padding: 25px 30px 10px;
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    //opacity: 0.75;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 5px;
    @media only screen and (max-width : $responsive-size-stand-mobile) {
      margin-bottom: 20px;
    }
    .border-text {
      padding: 5px;
      border: 1px solid white;
      border-radius: 5px;
    }
  }
}
.hide {
  .shadow {
    display: block;
  }
}
.shadow {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1047;
  background: rgba(30, 38, 50, 0.65);
  &.active {
    display: block;
  }
}

.status {
  display: block;
  &.open {
    color: #3C7CF9;
  }
  &.done {
    color: #36B84A;
  }
  &.canceled {
    color: #F36362;
  }
}
.dev-info {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  color: #292F45;
  p {
    margin: 0;
  }
  img {
    display: block;
    margin-bottom: 45px;
  }
  h2 {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #8F929D;
    margin-bottom: 5px;
  }
}
.copy {
  position: relative;
  overflow: hidden;
  padding: 0 70px 0 40px;
  height: 60px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #616A88;
  background: #F0F2F4;
  border-radius: 5px;
  width: 100%;
  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }
  button {
    background: none;
    border:none;
    cursor: pointer;
    position: absolute;
    top:0;
    right: 40px;
    height: 100%;
    transition: all linear 0.2s;
    svg {
      display: block;
      transition: all linear 0.2s;
    }
    &:hover {
      svg {
        path {
          fill: #FF7700;
        }
        rect {
          stroke: #FF7700;
        }
      }
    }
  }
}


.dark {
  background: #1F2435;
  .app {
    .link {
      color: #D1D7EB;
      &:hover {
        color: #FF7700;
      }
    }
    h1 {
      color: #fff;
    }
    .dashboard-block {
      .history-wrapper {
        background: #2E344B;
        .head {
          h3 {
            color: #D1D7EB;
            opacity: 0.75;
          }
        }
      }
      .total-holder {
        .inner {
          .item {
            &.total-stake {
              border-color: rgba(0, 255, 131, 0.25);
              background: rgba(0, 255, 131, 0.15);
              h3 {
                color: #4CDC97;
              }
            }
            &.total-liquidity {
              border-color: rgba(255, 176, 103, 0.25);
              background: rgba(255, 176, 103, 0.15);
              h3 {
                color: #FFA454;
              }
            }
            &.total-user {
              border-color: rgba(170, 178, 209, 0.25);
              background: #363B4E;
              h3 {
                color: #B5D1F3;
              }
            }
            .ico {
              background: rgba(31, 36, 53, 0.25);
            }
            .number {
              color:#fff;
            }
            .current-info {
              background: rgba(255, 255, 255, 0.15);
              .name {
                color: #FFFFFF;
                opacity: 0.75;
              }
              strong {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
    .responsiveTable {
      &.table {
        th {
          color: #FFFFFF;
        }
        td {
          color: #D1D7EB;
          a {
            color: #D1D7EB;
            &:hover {
              color: #FF7700;
            }
          }
        }
      }
      tbody {
        tr {
          &:nth-child(2n-1) {
            td {
              background: rgba(31, 36, 53, 0.3);
            }
          }
        }
      }
    }
    footer {
      background: #292F45;
    }
  }
}
.loading-holder {
  display: none;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(0,0,0,0.5);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  &.active {
    display: flex;
  }
}
.lds-ellipsis {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #dfc;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
