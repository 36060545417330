@import "../../_helpers/variables";
.modal-backdrop {
    background: rgba(30, 38, 50, 0.65) !important;
}
.modal-app {

    .modal-info-text {
        margin-top: 10px;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .modal-content {
        box-shadow: none;
        background: #2E344B;
        border-radius: 5px;

        .form-control {
            +.form-control {
                margin-top: 10px;
            }
        }

        .btn {

            @media only screen and (max-width : $responsive-size-mobile) {
                width: 100%;
            }
        }
    }
    .modal-content {
        padding-bottom: 40px;
    }
    .modal-footer {
        display: flex;
        justify-content: center;
        border: none;
        padding: 0 30px;

        @media only screen and (max-width : $responsive-size-big-screen) {
            padding: 0 16px;
        }
    }
    .modal-body {
        padding: 10px 30px 20px;

        @media only screen and (max-width : $responsive-size-mobile) {
            padding: 10px 16px 20px;
        }
    }
    .modal-header {
        padding: 20px 30px;
        border: none;
        text-align: center;
        position: relative;
        justify-content: center;
        
        h5.modal-title {
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            margin: 0;
            p {
                margin: 0;
            }
        }
        .close {
            position: absolute;
            top: 30px;
            right: 30px;
            padding: 0;
            margin: 0;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: none;
            font-size: 24px;
            color: #9FB0D0;
            opacity: 1;
        }
    }
    .form {
        width: 100%;
        margin: 0 auto ;

    }
        &.modal-connect {
            .modal-header {
                padding: 30px 0 20px;
                justify-content: center;
                position: relative;
                h5.modal-title {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 29px;
                    color: #FFFFFF;
                    justify-content: center;
                    width: 100%;
                }
                .btn-close {
                    width: 24px;
                    height: 24px;
                    top: 15px;
                    right: 15px;
                    position: absolute;
                    opacity: 1;
                    transition: all linear 0.2s;
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
            .modal-content {
                padding: 0;
            }
            .modal-body {
                padding: 0 30px 30px;
                @media only screen and (max-width : $responsive-size-stand-mobile) {
                    padding: 0 15px 15px;
                }
            }
        }
    }
    .connect-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media only screen and (max-width : 499px) {
            flex-direction: column;
        }
        .connect-link {
            width: 48%;
            &+.connect-link {
                @media only screen and (max-width : 499px) {
                    border-top: 1px solid rgba(244, 247, 254, 0.56);
                    border-radius: 0px;
                }
            }
            border: 1px solid #F4F7FE;
            border-radius: 5px;
            font-size: 14px;
            padding: 30px;
            color: #fff;
            text-align: center;
            text-decoration: none;
            @media only screen and (max-width : 499px) {
                width: 100%;
                border:none;
            }
            .ico {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 45px;
                margin-bottom: 15px;
                svg {
                    display: block;
                    height: 100%;
                    width: auto;
                }
            }
            span {
                display: block;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #fff;
            }
        }
    }

.connect-item {
    padding: 25px;
    font-size: 14px;
    color: #1E2632;
    a.connect-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        .ico {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            margin-bottom: 15px;
            svg {
                display: block;
                height: 100%;
                width: auto;
            }
        }
        strong {
            font-size: 18px;
            color: #fff;
            display: block;
            margin-bottom: 5px;
        }
        .text {
            display: block;
            font-weight: 300;
        }
    }
    &+.connect-item {
        border-left: 1px solid #e1e7ef;
        @media only screen and (max-width : $responsive-size-stand-mobile) {
            border-left: none;
            border-top: 1px solid #e1e7ef;
        }
    }
}
