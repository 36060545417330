@import "../../_helpers/variables";
body,
.app {
    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 10px;
        border: none;
        background: transparent;
        min-height: 50px;
        padding: 10px 50px;
        transition: all linear 0.2s;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;
        outline: none !important;

        &:focus {
            box-shadow: none;
        }
        svg {
            display: block;
            margin-right: 10px;
            transition: all linear 0.2s;
        }

        &.btn-default {
            border: none;
            background: rgba(255, 255, 255, 0.1);
            color: #9eabd3;

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }

            &:hover {
                color: #fae529;

                svg {
                    path {
                        fill: #fae529;
                    }
                }
            }
        }

        &.btn-primary {
            border: none;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            height: 60px;
            background: rgb(32, 86, 232);
            box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
            border-radius: 15px;

            &:hover {
                background: rgb(88, 129, 242);
                box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
            }
            &.btn-connect {
                @media only screen and (max-width: $responsive-size-stand-mobile) {
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #ffffff;
                    padding: 0 20px;
                }
                @media only screen and (max-width: $responsive-size-mobile) {
                    width: 110px;
                }
                svg {
                    @media only screen and (max-width: $responsive-size-stand-mobile) {
                        display: none;
                    }
                }
            }
        }
        &.btn-secondary {
            border: none;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            height: 60px;
            background: linear-gradient(85.1deg, #22a164 0%, #6decaf 100%);
            box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
            border-radius: 15px;

            &:hover {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%),
                    linear-gradient(85.1deg, #22a164 0%, #6decaf 100%);
                box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
            }
        }
        &.btn-tertiary {
            border: none;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            height: 60px;
            background: #97a0c3;
            box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
            border-radius: 15px;

            &:hover {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%),
                    linear-gradient(114.37deg, #3d4460 0%, #6b7392 100%);
                box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
            }
        }
        &.btn-theme {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border: 2px solid #3e4458;
            border-radius: 15px;
            svg {
                display: block;
                margin: 0;
                &.moon {
                    path {
                        stroke: #848ca9;
                    }
                }
                &.sun {
                    circle,
                    path {
                        stroke: #848ca9;
                    }
                }
            }
            &:hover {
                svg {
                    &.moon {
                        path {
                            stroke: url(#gradient);
                        }
                    }
                    &.sun {
                        circle {
                            stroke: url(#paint0_linear_116_973);
                        }
                        path {
                            &.st2 {
                                stroke: url(#paint1_linear_116_973);
                            }
                            &.st3 {
                                stroke: url(#paint2_linear_116_973);
                            }
                            &.st4 {
                                stroke: url(#paint3_linear_116_973);
                            }
                            &.st5 {
                                stroke: url(#paint4_linear_116_973);
                            }
                        }
                    }
                }
            }
        }
        &.btn-close {
            width: 50px;
            height: 50px;
            border: 2px solid #3e4458;
            border-radius: 15px;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
                display: block;
                margin-right: 0;
            }
            &:hover,
            &:active {
                border: 2px solid #9096aa;
                svg {
                    path {
                        fill: #9096aa;
                    }
                }
            }
        }
        &.btn-profile {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border: 2px solid #3e4458;
            border-radius: 15px;
            svg {
                display: block;
                margin-right: 0;
            }
        }
        &.btn-ico {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 15px;
            background: rgba(255, 255, 255, 0.1);
            svg {
                display: block;
                margin-right: 0;
            }
            &.btn-info {
                svg {
                    circle,
                    path {
                        stroke: #848ca9;
                    }
                }
                &:hover {
                    svg {
                        circle {
                            stroke: url(#paint0_linear_116_949);
                        }
                        path {
                            &.st1 {
                                stroke: url(#paint1_linear_116_949);
                            }
                            &.st2 {
                                stroke: url(#paint2_linear_116_949);
                            }
                        }
                    }
                }

                @media only screen and (max-width: $responsive-size-laptop-screen) {
                    svg {
                        circle {
                            stroke: url(#paint0_linear_116_949);
                        }
                        path {
                            &.st1 {
                                stroke: url(#paint1_linear_116_949);
                            }
                            &.st2 {
                                stroke: url(#paint2_linear_116_949);
                            }
                        }
                    }
                }
            }
            &.btn-logout {
                svg {
                    path {
                        path {
                            fill: #848ca9;
                        }
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: #ff7700;
                        }
                    }
                }

                //@media only screen and (max-width : $responsive-size-laptop-screen) {
                //  svg {
                //    path {
                //      fill:url(#paint0_linear_116_955);
                //    }
                //  }
                //}
            }
        }
        &.btn-selected {
            background: rgb(255, 0, 0);
            &:hover {
                background: rgb(255, 100, 100);
            }
        }
        &.btn-dynamic {
            background: radial-gradient(circle, #00f260, #0575e6);
            animation: neonPulse 5s infinite;
        }
        &.btn-wallet {
            background: rgb(0, 255, 0);
            //background: #2E344B;
            &:hover {
                background: rgb(100, 255, 100);
            }
        }
    }
}

@keyframes neonPulse {
    0%, 100% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(1.5);
    }
}
